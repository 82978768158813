import HttpClient from '../plugins/httpClient'
import { ApiGateway } from '../plugins/gateway'
import AppConstant from '../plugins/constant'
var httpClient = new HttpClient(ApiGateway.Emr);

const prefix = '/Service';

export default {
    searchService: (keySearch, clinicID, statusID,  page = 1, limit = 10) => {
        let query = {
            keySearch,
            clinicID,
            statusID
        };

        var headers = {
            page,
            limit: limit || AppConstant.DefaultPaginationLimit
        }
        return httpClient.get(`${prefix}/Search`, query, headers)
    },

    searchAll: () => httpClient.get(`${prefix}/Search/All`, {}, {})
}