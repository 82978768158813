import HttpClient from '../plugins/httpClient'
import { ApiGateway } from '../plugins/gateway'
//import AppConstant from '../plugins/constant'
var httpClient = new HttpClient(ApiGateway.Pas);

const prefix = '/AdmissionType';


export default {
    searchAll: () => httpClient.get(`${prefix}/Search`)
}