import styled from 'vue-styled-components';

export const TitleOpenCalculate = styled.span`
    cursor: pointer;
    font-family: "IBM Plex Sans", sans-serif;
    font-size: 12px;
    margin: 8px 0px;
    color: #0065FF;

    &:hover {
        font-weight: 450;
    }
`;

export const OnOffCalculate = styled('span', { active: Boolean })`
    color: ${props => props.active ? 'red' : '#0065FF'};
`;

export const BoxQuickCalculate = styled.div`
    margin-top: 12px;
    display: flex;
    flex-direction: row;
    .itemCalculate {
        width: 40%;
        display: block;
    }
`;

export const AlertDatePicker = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between !important;
    align-items: center;
    padding: 8px 10px;
    background-color: #dc3545;
    .alert__content {
        color: #ffffff;
        width: 70%;
        font-weight: 420;
        font-size: 16px;
        text-overflow: ellipsis;
    }

    .alert__icon {
        width: 20%;
        text-align: right;
        cursor: pointer;
    }
`;