<template>
  <v-badge
    :content="bookingOfPatient.totals"
    :value="bookingOfPatient.totals"
    color="red"
    overlap
  >
    <v-menu bottom left>
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on">
          <v-icon>mdi-menu</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item class="item-menu" @click="changeState('list')">
          <v-list-item-content>
            <v-list-item-title>
              {{ $t("examination.followUp.list") }}
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <CircleChip v-if="bookingOfPatient.totals > 0">
              {{ bookingOfPatient.totals }}
            </CircleChip>
          </v-list-item-action>
        </v-list-item>
        <v-list-item class="item-menu" @click="changeState('create')">
          <v-list-item-title>
            {{ $t("examination.followUp.createANew") }}</v-list-item-title
          >
        </v-list-item>
      </v-list>
    </v-menu>
  </v-badge>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { CircleChip } from "./css/style";
export default {
  name: "MenuActionBooking",
  components: {
    CircleChip,
  },
  computed: {
    ...mapState("FollowUp", ["bookingOfPatient"]),
  },

  created() {
    this.SET_PATIENT({ patientID: this.$route.params.patientId });
    this.getBookingPatient();
    this.getAllOptionalPatient();
  },
  methods: {
    ...mapMutations("FollowUp", ["SET_PATIENT"]),
    ...mapActions("FollowUp", ["getBookingPatient", "getAllOptionalPatient"]),

    changeState(state) {
      this.$emit("change-state", state);
    },
  },
};
</script>

<style>
</style>