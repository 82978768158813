import Holidays from "date-holidays";
const hd = new Holidays('VN');

export default {
    getCountryCode(country) {
        const countries = hd.getCountries();

        for (const countryCode in countries) {
            const countryName = countries[countryCode];

            if (countryName === country) {
                return countryCode;
            }
        }

        return null;
    },
    getListHolidays(year) {
        var holidays = hd.getHolidays(year);
        return holidays;
    },
    checkHolidays(date) {
        return hd.isHoliday(date)
    }
}

