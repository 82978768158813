import styled from 'vue-styled-components';

export const ContainerList = styled.div`
    display: flex;
    flex-direction: column;
`;

export const PatientDetail = styled.div`
    position: relative;
    width: 100%;
    display: block;
    .avatar__patient {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
    }
`;

export const PatientInfo = styled.div `
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    .item__detail {
        padding: 2px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .item__detail__name {
            font-size: 16px; 
            font-weight: 420;
        }
        .item__detail__age {
            font-size: 15px;
            font-weight: 400;
        }
        .item__detail__patientID {
            font-size: 16px;
        }
    }
`;
export const ItemFollowUp = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 10px 8px;
    background-color: #fafafa;
    cursor: pointer;
    margin: 5px 0px;
    .followUp__timing  {
        display: flex;
        justify-content:space-between;
        align-items: center;
    }
    .followUp__note {
        display: flex;
        align-items: center;
        .followUp__note__content {
            font-weight: 400;
            font-size: 14px;
        }
    }
    .followUp__boxIcon {
        position: absolute;
        top: -10px;
        right: 0;
        display: block;
       
        .followUp_boxIcon_edit {
            &:hover {
                color: #007bff;
            }
        }
        .followUp_boxIcon_remove {
            &:hover {
                color: #dc3545;
            }
        }
        
        
        
    }

    &:hover {
        background-color: #ebedf0;
    }
`;

export const CircleChip = styled.span`
    border-radius: 50%;
    font-size: 12px;
    color: #FFFFFF;
    padding: 2px 6px;
    background-color: #f44336;
`;

export const EventItem = styled.div`
    display: flex;
    flex-direction: column;
    background-color: #f5f5f5;
    margin: 5px 0px;
    cursor: pointer;

    .eventItem__patient {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 5px 8px;
        .eventItem__patient__info {
            font-size: 17px;
            font-weight: 450;
            display: flex;
            align-items: center;
        }
    }

    .eventItem__noteTime {
        display: flex;
        align-items: center;
        padding: 5px 8px;
        .eventItem__note {
           font-weight: 400;
           font-size: 17px;
        }

    }
`;
