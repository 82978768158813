<template>
  <v-card elevation="0">
    <v-card-title class="pr-0 pl-0">
      <div class="d-flex align-center">
        <!-- <v-btn
                    color="#007bff"
                    class="mr-2"
                    icon 
                    small 
                    tab
                    @click="goBackCalendar"
                >
                    <v-icon>
                        mdi-arrow-left-circle
                    </v-icon>
                </v-btn> -->

        <span class="text-subtitle">
          {{ $t("examination.followUp.listFollowOfDay") }}
          <b class="blue--text">{{ convertDateTime(detailDate) }}</b>
        </span>
      </div>
      <v-chip
        v-if="nameHoliday"
        class="w-100"
        label
        text-color="white"
        color="#0065ff"
        >{{ nameHoliday }}</v-chip
      >
    </v-card-title>

    <v-card-text class="pa-0">
      <ContainerList>
        <template v-if="!isEmtpyEvent">
          <EventItem
            v-for="(itemEvent, index) in eventOfDate"
            :key="index"
            class="pa-2"
          >
            <div class="eventItem__patient">
              <span class="eventItem__patient__info">
                <v-icon class="mr-1"> mdi-account </v-icon>
                {{ itemEvent.fullName }}
              </span>

              <span class="font-weight-bold blue--text">
                [{{ waitingTime(itemEvent.timeStart) }}]
              </span>
            </div>
            <div class="eventItem__noteTime">
              <b class="text-subtitle-1 font-weight-bold">{{
                $t("examination.followUp.note")
              }}</b
              >:
              <span class="ml-1 eventItem__note">{{ itemEvent.note }}</span>
            </div>
          </EventItem>
        </template>
        <template v-else>
          <span class="blue--text">
            {{ $t("examination.followUp.noneFollowUp") }}</span
          >
        </template>
      </ContainerList>
    </v-card-text>
  </v-card>
</template>

<script>
import _ from "lodash";
import moment from "moment";
import { mapState, mapMutations } from "vuex";
import { ContainerList, EventItem } from "./css/style";
export default {
  name: "EventOfDay",
  components: {
    ContainerList,
    EventItem,
  },

  computed: {
    ...mapState("FollowUp", ["eventOfMonth", "detailDate", "eventOfDate"]),
    isEmtpyEvent() {
      return _.isEmpty(this.eventOfDate);
    },
  },
  data: () => ({
    locate: null,
    nameHoliday: "",
  }),
  mounted() {
    this.locate = localStorage.getItem("LOCATE_LANGUAGE") || "vn";
  },
  methods: {
    ...mapMutations("FollowUp", ["SET_DETAIL_BOOKING_DATE"]),
    goBackCalendar() {
      this.SET_DETAIL_BOOKING_DATE({ open: false, date: "" });
    },
    getNameHoliday(name) {
      this.nameHoliday = name;
    },
    convertDateTime(time) {
      var timeString = null;

      if (this.locate == "vn") {
        timeString = moment(time).lang("vi").format(" Do MMMM  YYYY");
      } else {
        timeString = moment(time).format(" Do MMMM  YYYY");
      }
      return timeString;
    },
    waitingTime(time) {
      let start = moment(time).format("HH:mm");
      let end = moment(time).add(1, "hours").format("HH:mm");
      return `${start} - ${end}`;
    },
  },
};
</script>

<style>
</style>