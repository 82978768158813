<template>
  <ContainerList>
    <PatientDetail>
      <v-row>
        <v-skeleton-loader
          class="col-12"
          type="list-item-avatar-three-line"
          v-if="patient.loading"
        >
        </v-skeleton-loader>
        <template v-else>
          <template v-if="!patient.error">
            <!-- Avatar patient -->
            <v-col cols="2" md="2" sx="12" sm="12">
              <div class="avatar__patient">
                <v-avatar size="40" color="red">
                  <span class="white--text headline" v-if="!havingAvart">
                    {{ shortName }}
                  </span>
                  <img v-else :src="patient.data.avatar" />
                </v-avatar>
              </div>
            </v-col>
            <v-col cols="10" md="10" sx="12" sm="12">
              <PatientInfo>
                <div class="item__detail">
                  <span class="item__detail__name">
                    {{ patient.data.fullName }}
                  </span>
                </div>
                <div class="item__detail">
                  <span class="item__detail__age"
                    >Tuổi: {{ patient.data.age }}
                  </span>
                  <span class="item__detail__patientID">
                    <v-chip small label color="#007bff" class="white--text">
                      PaID: {{ patient.id }}
                    </v-chip>
                  </span>
                </div>
              </PatientInfo>
            </v-col>
          </template>
        </template>

        <v-col cols="12" v-if="patient.error">
          <v-alert
            v-model="patient.error"
            class="d-block w-100 white--text"
            dismissible
            color="#dc3545"
          >
            {{ patient.error_message }}
          </v-alert>
        </v-col>
      </v-row>
      <v-divider class="mt-3 mb-3" inset> </v-divider>
    <v-card elevation="0" class="pa-0">
    <v-card-title>
      <v-tabs v-model="tab">
        <v-tabs-slider></v-tabs-slider>
        <v-tab
          v-for="(itemTab, index) in listTab"
          :key="index"
          class="primary--text pl-5 pr-5"
        >
          <span class="text-h6">{{ itemTab }}</span>
        </v-tab>
      </v-tabs>
    </v-card-title>
    <v-card-text class="pa-0">
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <template v-if="bookingOfPatient.loading">
                <v-skeleton-loader
                  class="col-12"
                  type="list-item-two-line"
                ></v-skeleton-loader>
              </template>
              <template v-else>
                <template v-if="!bookingOfPatient.error">
                  <div class="d-block mt-3 mb-3">
                    {{ $t("examination.followUp.bookingFrom") }}:
                    <b class="ml-1">{{ today }}</b>
                  </div>
                  <template v-if="!isEmptyBookingData">
                    <ItemFollowUp
                      v-for="(itemBooking, index) in bookingOfPatient.items"
                      :key="index"
                    >
                      <div class="followUp__timing">
                        <span class="font-weight-bold">{{
                          itemBooking.dateVisit
                        }}</span>
                        <span class="">
                          [{{ itemBooking.atStart }} - {{ itemBooking.atEnd }}]
                        </span>
                      </div>
                      <div class="followUp__note mt-1">
                        <span class="mr-2 font-weight-bold">
                          {{ $t("examination.followUp.note") }}</span
                        >
                        <span class="followUp__note__content">
                          {{ showNote(itemBooking.note) }}
                        </span>
                      </div>
                      <div class="followUp__boxIcon">
                        <v-icon
                          small
                          class="mr-2 followUp_boxIcon_edit"
                          @click="edit(itemBooking.bookingID)"
                        >
                          mdi-circle-edit-outline
                        </v-icon>

                        <v-icon
                          small
                          class="followUp_boxIcon_remove"
                          @click="remove(itemBooking.bookingID)"
                        >
                          mdi-close-circle
                        </v-icon>
                      </div>
                    </ItemFollowUp>
                  </template>
                  <template v-else>
                    <v-alert class="d-block w-100 white--text" color="#5b93d2">
                      {{ $t("examination.followUp.noneFollowUp") }}
                    </v-alert>
                  </template>
                </template>
                <v-alert v-if="bookingOfPatient.error">
                  <v-alert
                    class="d-block w-100 white--text"
                    dismissible
                    color="#dc3545"
                  >
                    {{ bookingOfPatient.error_message }}
                  </v-alert>
                </v-alert>
              </template>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <template v-if="noteOfPatient.loading">
                <v-skeleton-loader
                  class="col-12"
                  type="list-item-two-line"
                ></v-skeleton-loader>
              </template>
              <template v-else>
                <template v-if="!noteOfPatient.error">
                  <div class="d-block mt-3 mb-3">
                    {{ $t("examination.followUp.noteFrom") }}:
                    <b class="ml-1">{{ today }}</b>
                  </div>
                  <template v-if="!isEmptyNoteData">
                    <ItemFollowUp
                      v-for="(itemNote, index) in noteOfPatient.items"
                      :key="index"
                    >
                      <div class="followUp__timing">
                        <span class="font-weight-bold">{{
                          itemNote.dateVisit
                        }}</span>
                        <span class="">
                          [ Note ]
                        </span>
                      </div>
                      <div class="followUp__note mt-1">
                        <span class="mr-2 font-weight-bold">
                          {{ $t("examination.followUp.note") }}</span
                        >
                        <span class="followUp__note__content">
                          {{ showNote(itemNote.note) }}
                        </span>
                      </div>
                      <div class="followUp__boxIcon">
                        <v-icon
                          small
                          class="mr-2 followUp_boxIcon_edit"
                          @click="edit(itemNote.bookingID)"
                        >
                          mdi-circle-edit-outline
                        </v-icon>
                        <v-icon
                          small
                          class="followUp_boxIcon_remove"
                          @click="remove(itemNote.bookingID)"
                        >
                          mdi-close-circle
                        </v-icon>
                      </div>
                    </ItemFollowUp>
                  </template>
                  <template v-else>
                    <v-alert class="d-block w-100 white--text" color="#5b93d2">
                      {{ $t("examination.followUp.noneFollowUp") }}
                    </v-alert>
                  </template>
                </template>
                <v-alert v-if="noteOfPatient.error">
                  <v-alert
                    class="d-block w-100 white--text"
                    dismissible
                    color="#dc3545"
                  >
                    {{ noteOfPatient.error_message }}
                  </v-alert>
                </v-alert>
              </template>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
  </v-card>

    </PatientDetail>
  </ContainerList>
</template>

<script>
import i18n from "@/plugins/i18n";
import { mapState, mapActions } from "vuex";
import { getNameInnitial } from "@/plugins/helper";
import moment from "moment";
import _ from "lodash";
import PatientService from "@/services/patient";
import BookingService from "@/services/booking";
import {
  ContainerList,
  PatientDetail,
  PatientInfo,
  ItemFollowUp,
} from "./css/style";
export default {
  name: "ListFollowUpPatient",
  components: {
    ContainerList,
    PatientDetail,
    PatientInfo,
    ItemFollowUp,
  },
  computed: {
    ...mapState("FollowUp", ["bookingOfPatient", "noteOfPatient"]),
    havingAvart() {
      return this.patient.data.avatar != "" ? true : false;
    },
    shortName() {
      return getNameInnitial(this.patient.data.fullName);
    },
    today() {
      return moment().format("DD-MM-YYYY");
    },
    isEmptyBookingData() {
      return _.isEmpty(this.bookingOfPatient.items);
    },
    isEmptyNoteData() {
      return _.isEmpty(this.noteOfPatient.items);
    },
  },
  data: () => ({
    tab: 0,
    listTab: [i18n.t("examination.followUp.booking"), i18n.t("examination.followUp.noteBooking")],
    patient: {
      loading: false,
      id: null,
      data: null,
      error: false,
      error_message: "",
    },
    bookingData: {
      loading: false,
      data: null,
      error: false,
      error_message: "",
    },
  }),
  created() {
    this.patient.id = parseInt(this.$route.params.patientId);
    this.getPatientDetail();
  },
  mounted() {},
  methods: {
    getNameInnitial,
    ...mapActions("FollowUp", ["getBookingPatient", "getAllOptionalPatient", "updateEventByMonth"]),
    async getPatientDetail() {
      this.patient.loading = true;
      var result = await PatientService.getPatientDetail(this.patient.id);
      if (result.error) {
        //this.patient.loading = false;
        this.patient.error = true;
        this.patient.error_message = "Can not get detail patient!";
        return;
      }
      this.patient.loading = false;
      this.patient.data = result.personalInfo;
      this.patient.data.fullName = result.personalInfo.fullName;
      this.patient.data.age =
        moment().format("YYYY") -
        moment(result.personalInfo.birthDate).format("YYYY");
    },
    showNote(note) {
      return note != null ? note : "...";
    },
    edit(bookingID) {
      this.$emit("detail-booking", bookingID);
    },
    async remove(bookingID) {
      var deleteBooking = await BookingService.deleteBooking(bookingID);
      if (deleteBooking.error) {
        return;
      }
      this.getBookingPatient();
      this.getAllOptionalPatient();
      this.updateEventByMonth();
    },
  },
};
</script>
<style lang="scss">
.cover-test-spec-tabs.v-tabs {
  .v-tabs-slider {
    width: 0px;
  }
  > .v-tabs-bar {
    border-top: 1px solid rgba(0, 0, 0, 0.6);
    .v-tab {
      border: 1px solid rgba(0, 0, 0, 0.6);
      margin-top: -1px;
      font-size: 11px !important;
      font-family: "Montserrat" !important;
      color: #424242 !important;
      min-width: 200px;
      justify-content: left;
    }
    .v-tab--active {
      background: #eeeeee;
      font-weight: bold;
    }
  }
  .v-tabs-bar.v-item-group > * {
    cursor: initial;
  }
}
</style>