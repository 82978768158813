<template>
  <div class="ma-0 pa-0">
    <v-row justify="space-between">
      <v-col md="6" sx="12" sm="12" class="pa-0">
        <v-card elevation="0">
          <div
            class="d-flex mt-3 mb-4 align-center pl-3"
            v-if="!quickCalculateDate.show"
          >
            <v-chip
              class="custom-chip-label"
              label
              text-color="white"
              color="#0065ff"
              >{{ dateTimeFollowUp }}</v-chip
            >
          </div>
          <v-card-title class="d-flex align-center">
            <span class="text-subtitle-1 font-weight-bold">{{
              titleStateAction
            }}</span>
            <v-spacer></v-spacer>
            <ActionMenuFollowUp @change-state="changeState" />
          </v-card-title>
          <v-card-text>
            <v-sheet v-if="stateAction.create || stateAction.update">
              <v-form ref="bookingForm" lazy-validation v-model="isValid">
                <!-- Calculate date -->
                <div class="d-flex flex-column">
                  <!-- Quickly calculation -->
                  <div class="d-block">
                    <BoxQuickCalculate>
                      <div class="d-flex flex-column">
                        <span class="text-subtitle-1 font-weight-bold">{{
                          $t("examination.followUp.number")
                        }}</span>
                        <v-combobox
                          v-model="quickCalculateDate.selectNumber"
                          :items="quickCalculateDate.listNumberDate"
                          outlined
                          dense
                        ></v-combobox>
                      </div>

                      <div class="d-flex flex-column ml-3">
                        <span class="text-subtitle-1 font-weight-bold"
                          >{{ $t("examination.followUp.day") }}|{{
                            $t("examination.followUp.week")
                          }}|{{ $t("examination.followUp.month") }}</span
                        >
                        <v-select
                          v-model="quickCalculateDate.selectType"
                          :items="quickCalculateDate.listTypeTime"
                          outlined
                          dense
                        ></v-select>
                      </div>
                    </BoxQuickCalculate>
                  </div>
                  <!-- Manual selection date -->
                </div>

                <!-- Booking time -->
                <div class="d-flex flex-column">
                  <span class="text-subtitle-1 font-weight-bold">{{
                    $t("examination.followUp.time")
                  }}</span>
                  <v-select
                    v-model="selectionTime"
                    :items="bookingTime"
                    outlined
                    dense
                    :rules="timeRules"
                  >
                  </v-select>
                </div>
                <!-- Admission Type -->
                <div class="d-flex flex-column">
                  <span class="text-subtitle-1 font-weight-bold">{{
                    $t("examination.followUp.admissionType")
                  }}</span>
                  <v-select
                    v-model="listAdmissionType.selection"
                    dense
                    outlined
                    :items="listAdmissionType.items"
                    item-text="codeDescription"
                    item-value="admissionTypeID"
                    :rules="[(v) => !!v || 'Admission Type is required']"
                  >
                  </v-select>
                </div>
                <!-- Select service -->
                <div class="d-flex flex-column">
                  <span class="text-subtitle-1 font-weight-bold">{{
                    $t("examination.followUp.service")
                  }}</span>
                  <v-combobox
                    v-model="listService.selection"
                    :items="listService.items"
                    :error="listService.error"
                    :error-messages="listService.error_messages"
                    outlined
                    clearable
                    dense
                    item-value="id"
                    item-text="description"
                    :rules="serviceRules"
                  ></v-combobox>
                </div>
                <!-- Note -->
                <div class="d-flex flex-column">
                  <span class="text-subtitle-1 font-weight-bold">{{
                    $t("examination.followUp.note")
                  }}</span>
                  <v-textarea 
                    v-model="bookingData.note" 
                    outlined
                    :rules="descriptionRules"
                  >
                  </v-textarea>
                </div>

                <div class="d-flex justify-space-between">
                  <v-btn 
                    class="white--text" 
                    color="secondary" 
                    ripple
                    @click="refreshData"
                  >
                    reset
                  </v-btn
                  >
                  <v-btn
                    ripple
                    color="#0065FF"
                    class="px-4 py-3 white--text"
                    @click="onSubmit"
                    :loading="submitBtnLoading"
                  >
                    {{ contentButton }}
                  </v-btn>
                </div>
              </v-form>
            </v-sheet>

            <v-sheet v-if="stateAction.list">
              <ListFollowUp @detail-booking="detailBooking" />
            </v-sheet>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col md="1"></v-col>
      <v-col md="4" sx="12" sm="12">
        <!-- Calendar date picker -->
        <v-card elevation="0" class="ma-0 pa-0">
          <v-card-text class="ma-0 pa-0">
            <!-- Date Picker Calendar -->
            <v-date-picker
              ref="bookingCalendar"
              v-model="datePicker.date"
              color="#0065ff"
              full-width
              show-adjacent-months
              :events="renderListDateEvent"
              event-color="#ff5630"
              @click:date="viewDetailBookingDate"
              header-color="#0D47A1"
              class="elevation-0"
              style="border: 1px solid rgba(0, 0, 0, 0.12)"
              locale="vn"
            >
            </v-date-picker>
            <!-- View detail list booking of Date -->
            <EventOfDay ref="EventOfDay" />

            <AlertDatePicker v-if="datePicker.error">
              <span class="alert__content">
                {{ datePicker.error_message }}
              </span>
              <span class="alert__icon">
                <v-icon @click="datePicker.error = false" small color="#ffffff"
                  >mdi-close</v-icon
                >
              </span>
            </AlertDatePicker>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- Form Follow-up -->
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import ListFollowUp from "@/components/followUp/ListFollowUp";
import BookingService from "@/services/booking";
import AdmissionTypeService from "@/services/admissionType";
import HolidayService from "@/services/holiday";
import ServicePackage from "@/services/servicePackage";
import moment from "moment";
import { TimeStringsOptions } from "@/plugins/constant";
import { BoxQuickCalculate, AlertDatePicker } from "./css/style";
import ActionMenuFollowUp from "@/components/followUp/ActionMenu";
import EventOfDay from "@/components/followUp/EventOfDay";
import { awaitAll } from "@/plugins/helper";
import i18n from "@/plugins/i18n";

export default {
  name: "FollowUpPage",
  components: {
    BoxQuickCalculate,
    AlertDatePicker,
    ListFollowUp,
    ActionMenuFollowUp,
    EventOfDay,
  },
  computed: {
    ...mapState("FollowUp", ["eventListDateOfMonth", "openDetailBookingDate"]),
    statusQuickCalculate() {
      return this.quickCalculateDate.show ? "Close" : "Open";
    },
    timeRules() {
      const rules = [];

      if(this.selectionTime == null && this.listService.selection != null) {
        const rule = (v) => !!v || 'Time is required!';
        rules.push(rule);
      }
      if(this.listService.selection == null && this.selectionTime == null){
        return rules;
      }
      return rules;
    },
    serviceRules() {
      const rules = [];

      if(this.listService.selection == null && this.selectionTime != null) {
        const rule = (v) => !!v || 'Service Type is required!';
        rules.push(rule);
      }
      if(this.listService.selection == null && this.selectionTime == null){
        return rules;
      }
      return rules;
    },
    descriptionRules() {
      const rules = [];

      if(this.listService.selection == null && this.selectionTime == null ) {
        const rule = (v) => !!v || 'Description is empty!';
        rules.push(rule);
      }
      return rules;
    },
    dateTimeFollowUp() {
      var dateString = null;
      if (this.locate == "vn") {
        dateString = moment(this.datePicker.date)
          .lang("vi")
          .format("dddd , Do MMMM  YYYY");
      } else {
        dateString = moment(this.datePicker.date).format(
          "dddd , Do MMMM  YYYY"
        );
      }
      return dateString.charAt(0).toUpperCase() + dateString.slice(1);
    },
    bookingTimeVisit() {
      if (this.selectionTime != null) {
        let time = `${this.datePicker.date} ${this.selectionTime}`;
        let start = moment(time).format("HH:mm");
        let end = moment(time).add(1, "hour").format("HH:mm");
        return `${start} - ${end}`;
      }
      return "";
    },
    selectionService() {
      return this.listService.selection != null
        ? this.listService.selection.description
        : "";
    },
    titleStateAction() {
      if (this.stateAction.create) {
        return i18n.t("examination.followUp.createFollowUp");
      } else if (this.stateAction.list) {
        return i18n.t("examination.followUp.listFollowNext");
      }
      return i18n.t("examination.followUp.updateFollowUp");
    },
    contentButton() {
      if (this.stateAction.create) {
        return i18n.t("examination.followUp.createFollowUp");
      } else if (this.stateAction.update) {
        return i18n.t("examination.followUp.updateFollowUp");
      }
      return "";
    },
  },
  watch: {
    "listAdmissionType.selection": {
      deep: true,
      handler() {
        this.bookingData.bookingType = this.listAdmissionType.selection;
      },
    },
    "listService.selection": {
      deep: true,
      handler() {
        if (this.listService.selection != null) {
          this.bookingData.serviceID = this.listService.selection.id;
        }
      },
    },
    "datePicker.date": {
      deep: true,
      async handler() {
        if (this.validateDatePicker()) {
          this.datePicker.error = false;
          this.parseEndStartBookingTime();
          this.parseTimeStartEnd();

          // this.viewDetailBookingDate(this.datePicker.date);
        }
      },
    },
    selectionTime() {
      this.parseEndStartBookingTime();
    },
    "quickCalculateDate.selectNumber": {
      deep: true,
      handler() {
        this.parseEndStartByCalculation();
      },
    },
    "quickCalculateDate.selectType": {
      deep: true,
      handler() {
        this.parseEndStartByCalculation();
      },
    },
  },
  data: () => ({
    stateAction: {
      list: false,
      create: true,
      update: false,
    },
    datePicker: {
      date: moment().add(1, "days").format("YYYY-MM-DD"),
      error: false,
      error_message: "",
    },

    isErrorDate: false,
    isValid: true,

    selectionTime: null,
    bookingTime: TimeStringsOptions,

    bookingData: {
      bookingID: null,
      clinicID: null,
      serviceID: null,
      patientID: null,
      timeStart: null,
      timeEnd: null,
      bookingType: null,
      note: null,
    },
    listService: {
      error: false,
      error_messages: "",
      items: [],
      selection: null,
    },
    listAdmissionType: {
      error: false,
      error_message: "",
      items: [],
      selection: null,
    },
    listBookingFuture: {
      totals: 0,
    },

    quickCalculateDate: {
      show: false,
      listNumberDate: [],
      listTypeTime: ["Day", "Week", "Month"],
      selectNumber: null,
      selectType: null,
    },
    submitBtnLoading: false,
    locate: null,
    currentYear: moment().format("YYYY"),
  }),
  created() {
    this.bookingData.clinicID = this.$store.getters.userData.clinicID;
    this.SET_CLINIC({ clinicID: this.bookingData.clinicID });
    // call to load all service
    this.loadListServicePackage();
    this.loadListAdmissionType();
    this.generateNumberDate();
    this.parseTimeStartEnd();
    this.parseEndStartBookingTime();

    if (this.$route.params.patientId) {
      this.bookingData.patientID = parseInt(this.$route.params.patientId);
    }
  },
  mounted() {
    this.viewDetailBookingDate(this.datePicker.date);
    this.locate = localStorage.getItem("LOCATE_LANGUAGE") || "vn";
  },
  methods: {
    ...mapMutations("FollowUp", ["SET_CLINIC", "SET_DETAIL_BOOKING_DATE"]),
    ...mapActions("FollowUp", [
      "getEventByMonth",
      "getBookingPatient",
      "getAllOptionalPatient",
      "updateEventByMonth",
    ]),
    showError(msg = "", type = "error") {
      this.status = { type: type, show: true, message: msg };
    },
    renderListDateEvent(date) {
      const holidays = HolidayService.getListHolidays(this.currentYear);
      if (
        holidays.some((e) => moment(e.date).format("YYYY-MM-DD") === date) &&
        this.eventListDateOfMonth.some((e) => e == date)
      ) {
        return ["red", "#00f"];
      }
      if (holidays.some((e) => moment(e.date).format("YYYY-MM-DD") === date)) {
        return ["#00f"];
      }
      if (this.eventListDateOfMonth.some((e) => e == date)) {
        return ["red"];
      }
    },
    onSubmit() {
      if (this.$refs.bookingForm.validate()) {
        if (this.validateDatePicker()) {
          if (this.stateAction.create) {
            this.createBooking();
            return;
          }
          if (this.stateAction.update) {
            this.updateBooking();
            return;
          }
        }
      }
    },
    // get all service
    async loadListServicePackage() {
      var resultListService = await ServicePackage.searchAll();
      if (resultListService.error) {
        this.listService = {
          items: [],
          error: true,
          error_messages: "Can not load list service!",
        };
        return;
      }
      this.listService.items = resultListService.items;
    },

    // get admission type List
    async loadListAdmissionType() {
      var result = await AdmissionTypeService.searchAll();
      if (result.error) {
        this.listAdmissionType.error = true;
        this.listAdmissionType.error_messages =
          "Can not load list admission type!";
        return;
      }
      this.listAdmissionType.items = result.items;
      // find follow up item
      let defaultItem = result.items.find((i) =>
        i.codeDescription
          .toLowerCase()
          .replace(/[\W_]/g, "")
          .includes("followup")
      );
      this.listAdmissionType.selection = defaultItem.admissionTypeID;
      // set default bookingType
      this.bookingData.bookingType = this.listAdmissionType.selection;
    },

    // create a new booking
    async createBooking() {
      this.submitBtnLoading = true;
      console.log(this.bookingData);
      var creataion = await BookingService.createNewBookingFollowUp(this.bookingData);
      if (creataion.error) {
        this.submitBtnLoading = false;
        this.$toast.error("Can not create a follow-up!");
        return;
      }
      this.submitBtnLoading = false;
      this.stateAction = { list: true, create: false, update: false };
      this.$toast.success(i18n.t("message.followUp"));
      await awaitAll([
        this.getBookingPatient(), // update list booking of Patient ( vuex store)
        this.getAllOptionalPatient(), // update list optional of Patient ( vuex store)
        this.updateEventByMonth(), // call that to update list event of calendar via month
      ]);
      this.viewDetailBookingDate(this.datePicker.date);
    },

    // update a booking
    async updateBooking() {
      this.submitBtnLoading = true;
      let updateBooking = await BookingService.updateDetailBooking(
        this.bookingData.bookingID,
        this.bookingData
      );
      if (updateBooking.error) {
        this.submitBtnLoading = false;
        this.$toast.error("Can not update a detail's follow-up!");
        return;
      }
      this.submitBtnLoading = false;
      this.$toast.success("Update a follow-up successfully!");
      this.stateAction = { list: true, create: false, update: false };
      await awaitAll([
        this.getBookingPatient(), // update list booking of Patient ( vuex store)
        this.getAllOptionalPatient(), // update list optional of Patient ( vuex store)
        this.updateEventByMonth(), // call that to update list event of calendar via month
      ]);
      this.viewDetailBookingDate(this.datePicker.date);
    },

    generateNumberDate() {
      for (let i = 1; i <= 31; i++) {
        this.quickCalculateDate.listNumberDate.push(i);
      }
    },
    parseEndStartBookingTime() {
      if (this.bookingTime != null) {
        if(this.selectionTime != null) {
          let time = `${this.datePicker.date} ${this.selectionTime}`;
          let dateTime = moment(time).format("YYYY-MM-DD HH:mm:ss");
          this.bookingData.timeStart = dateTime;
          this.bookingData.timeEnd = dateTime;
        }
        else {
          let time = `${this.datePicker.date} 00:00`;
          let dateTime = moment(time).format("YYYY-MM-DD HH:mm:ss");
          this.bookingData.timeStart = dateTime;
          this.bookingData.timeEnd = dateTime;
        }
      }
    },
    parseEndStartByCalculation() {
      if (
        this.quickCalculateDate.selectNumber != null &&
        this.quickCalculateDate.selectType != null
      ) {
        let bookingTime = moment().add(
          this.quickCalculateDate.selectNumber,
          this.quickCalculateDate.selectType
        );
        let formatTime = bookingTime.format("YYYY-MM-DD");
        this.datePicker.date = formatTime;
        this.viewDetailBookingDate(formatTime);
      }
    },
    // set start-end date of Month
    parseTimeStartEnd() {
      // belong to datePicker.date
      let startOfMonth = moment(this.datePicker.date)
        .startOf("month")
        .format("YYYY-MM-DD");
      let endOfMonth = moment(this.datePicker.date)
        .endOf("month")
        .format("YYYY-MM-DD");
      this.getEventByMonth({
        startOfMonth,
        endOfMonth,
        datePicker: this.datePicker.date,
      });
    },

    // validation booking
    validateDatePicker() {
      let picker = this.datePicker.date;
      if (moment().isBefore(picker)) {
        return true;
      } else {
        this.datePicker.error = true;
        this.datePicker.error_message =
          "The follow-up day should be bigger than today!";
        return false;
      }
    },

    // when click booking item from list booking - load record booking to update
    async detailBooking(bookingID) {
      this.stateAction = { show: false, create: false, update: true };
      // call request to get detail booking
      var detailBooking = await BookingService.getDetailBooking(bookingID);
      if (detailBooking.error) {
        return;
      }
      if (moment(detailBooking.timeStart).format('HH:mm') == '00:00') {
        this.bookingData.timeStart = null;
        this.bookingData.timeEnd = null;
      }

      this.bookingData = {
        bookingID: bookingID,
        clinicID: detailBooking.clinicID,
        serviceID: detailBooking.serviceID,
        patientID: detailBooking.patientID,
        timeStart: detailBooking.timeStart,
        timeEnd: detailBooking.timeEnd,
        bookingType: detailBooking.bookingType,
        note: detailBooking.note,
      };

      let time;
      if (moment(this.bookingData.timeStart).format("HH:mm") == "00:00") {
        time = null;
      }
      else {
        time = moment(this.bookingData.timeStart).format("HH:mm");
      }

      this.selectionTime = time;
      this.listService.selection = this.listService.items.find(
        (i) => i.id == this.bookingData.serviceID
      );
      this.datePicker.date = moment(this.bookingData.timeStart).format(
        "YYYY-MM-DD"
      );
      this.listAdmissionType.selection = this.bookingData.bookingType;
    },

    // change State - creation & list => refresh data
    changeState(state) {
      this.refreshData();
      if (state == "create") {
        this.stateAction = { list: false, create: true, update: false };
      } else if (state == "list") {
        this.stateAction = { list: true, create: false, update: false };
      }
    },
    refreshData() {
      this.bookingData = {
        clinicID: this.$store.getters.userData.clinicID,
        patientID: parseInt(this.$route.params.patientId),
        bookingID: null,
        serviceID: null,
        timeStart: null,
        timeEnd: null,
        bookingType: null,
        note: null,
      };
      this.listService.selection = null;
      this.selectionTime = null;
      this.datePicker.date = moment().add(1, "days").format("YYYY-MM-DD");
      this.parseEndStartBookingTime();
      this.loadListServicePackage();
      this.loadListAdmissionType();
    },
    viewDetailBookingDate(eDate) {
      console.log(eDate);
      var holidayData = HolidayService.checkHolidays(eDate);
      var nameHoliday = "";
      if (holidayData == false) {
        nameHoliday = "";
      } else {
        nameHoliday = holidayData[0].name;
      }
      this.$refs.EventOfDay.getNameHoliday(nameHoliday || "");
      this.SET_DETAIL_BOOKING_DATE({
        open: true,
        date: eDate,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.follow-up-page {
  .v-date-picker-table {
    .v-date-picker-table__current.v-btn--outlined {
      border-radius: 0 !important;
      border-top: none !important;
      border-left: none !important;
      border-right: none !important;
    }
  }
  .a2ds-datetime {
    width: 400px;
  }
  .title-form {
    color: #000000;
    font-weight: bold;
  }
}

.v-picker__title {
  padding: 5px 10px;
}
.item-menu {
  cursor: pointer;
}
.item-menu:hover {
  background-color: #eaeaea;
}
.v-date-picker-title__date > div {
  font-size: 23px;
  font-weight: 400;
}
.custom-chip-label {
  font-size: 18px;
  height: 40px;
}
</style>

